const SUNCORP_CONSTANTS = {
  // Readable brand name used in the display
  brandName: 'SUNCORP',
  // Readable brand name used in the PRNPolicyDisplay Page
  brandNameCaseSense: 'Suncorp',
  // Brand name used in API calls
  apiBrandName: 'SUNCORP',
  supportedCards: ['visa', 'mastercard', 'americanexpress', 'eftpos'],
  links: {
    wdmLoginLink: 'https://online.suncorp.com.au/wdm/suncorp/#/login',
    contactUsLink: 'https://www.suncorp.com.au/insurance/contact-us.html',
    contactUsTelLink: 'tel:131155',
    privacyStatementLink:
      'https://www.suncorp.com.au/about-us/legal/online-terms.html',
  },
  contactNumber: '13 11 55',
  copyrightStatement:
    '© AAI Limited ABN 48 005 297 807 AFSL No. 230859 trading as Suncorp Insurance',
};

module.exports = SUNCORP_CONSTANTS;
