const generateCSP = (brandName) => {
  const csp = {
    'default-src': ["'self'", "'unsafe-eval'", 'data:'],
    'script-src': [
      "'self'",
      "'unsafe-eval'",
      'data:',
      `http://*.${brandName}.com.au`,
      'http://*.plavxml.com',
      `https://*.${brandName}.com.au`,
      'https://*.2o7.net',
      'https://*.aainsurance.co.nz',
      'https://*.asteronlife.co.nz',
      'https://*.cilinsurance.com.au',
      'https://*.demdex.net',
      'https://*.ensighten.com',
      'https://*.gbqofs.io',
      'https://*.linkedin.com',
      'https://*.omtrdc.net',
      'https://*.plavxml.com',
      'https://*.vero.co.nz',
      'https://*.suncorp.com.au',
      'https://*.usersnap.com',
      'https://*.yahoo.com',
      'https://*.yimg.com',
      'https://analytics.google.com',
      'https://cdn.gbqofs.com',
      'https://connect.facebook.net',
      'https://cm.everesttech.net',
      'https://payment.suncorp.com.au',
      'https://sc-static.net',
      'https://snap.licdn.com',
      'https://tr.snapchat.com',
      'https://www.google-analytics.com',
      'https://www.googletagmanager.com',
    ],
    'connect-src': [
      "'self'",
      'data:',
      `https://*.${brandName}.com.au`,
      'https://*.aainsurance.co.nz',
      'https://*.asteronlife.co.nz',
      'https://*.cilinsurance.com.au',
      'https://*.demdex.net',
      'https://*.ensighten.com',
      'https://*.gbqofs.io',
      'https://*.linkedin.com',
      'https://*.omtrdc.net',
      'https://*.suncorp.com.au',
      'https://*.vero.co.nz',
      'https://*.yahoo.com',
      'https://*.yimg.com',
      'https://assets.adobedtm.com',
      'https://analytics.google.com',
      'https://app.launchdarkly.com',
      'https://cdn.gbqofs.com',
      'https://clientstream.launchdarkly.com',
      'https://cm.everesttech.net',
      'https://connect.facebook.net',
      'https://events.launchdarkly.com',
      'https://facebook.com',
      'https://sc-static.net',
      'https://stats.g.doubleclick.net',
      'https://www.google-analytics.com',
      'https://www.google.com',
      'https://www.google.com.au',
      'www.google-analytics.com',
    ],
    'img-src': [
      "'self'",
      'data:',
      `http://assets1.${brandName}.com.au`,
      'https://*.aainsurance.co.nz',
      'https://*.asteronlife.co.nz',
      'https://*.cilinsurance.com.au',
      'https://*.doubleclick.net',
      'https://*.demdex.net',
      'https://*.ensighten.com',
      'https://*.gbqofs.io',
      'https://*.linkedin.com',
      'https://*.vero.co.nz',
      'https://assets.adobedtm.com',
      `https://assets1.${brandName}.com.au`,
      'https://cdn.gbqofs.com',
      'https://cm.everesttech.net',
      `https://payonline.${brandName}.com.au`,
      'https://styleguide-assets.suncorp.com.au',
      `https://styleguide-assets.${brandName}.com.au`,
      `https://styleguide-assets-test.${brandName}.com.au`,
      'https://www.google.com.au',
      'https://www.googletagmanager.com',
      'https://www.google-analytics.com',
      'https://www.facebook.com',
      'www.google-analytics.com',
    ],
    'frame-src': [
      'https://*.secureccc.suncorp.com.au',
      'https://*.demdex.net',
      'https://*.doubleclick.net',
      'https://secureccc.suncorp.com.au',
    ],
    'font-src': [
      "'self'",
      'data:',
      'ms-browser-extension:',
      'https://fonts.gstatic.com',
      `https://*.${brandName}.com.au`,
      'https://*.suncorp.com.au',
    ],
    'style-src': ['*', 'data:'],
  };

  // Combine the directives into a single string
  return Object.entries(csp)
    .map(([directive, sources]) => `${directive} ${sources.join(' ')}`)
    .join('; ');
};

// Function to insert the generated CSP meta tag into the document head
const injectCSP = () => {
  const cspContent = generateCSP(process.env.REACT_APP_STYLEGUIDE_BRAND);
  const metaTag = document.createElement('meta');
  metaTag.httpEquiv = 'Content-Security-Policy';
  metaTag.content = cspContent;

  // Insert the meta tag into the <head> of the HTML document
  document.head.appendChild(metaTag);
};

export default injectCSP;
