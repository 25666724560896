const GIO_CONSTANTS = {
  // Readable brand name used in the display
  brandName: 'GIO',
  // Readable brand name used in the PRNPolicyDisplay Page
  brandNameCaseSense: 'GIO',
  // Brand name used in API calls
  apiBrandName: 'GIO',
  supportedCards: ['visa', 'mastercard', 'americanexpress', 'eftpos'],
  links: {
    wdmLoginLink: 'https://online.gio.com.au/wdm/gio/#/login',
    contactUsLink: 'https://www.gio.com.au/contact-us.html',
    contactUsTelLink: 'tel:131010',
    privacyStatementLink: 'https://www.gio.com.au/privacy.html',
  },
  contactNumber: '13 10 10',
  copyrightStatement: '© Copyright 2013 AAI Limited ABN 48 005 297 807',
};

module.exports = GIO_CONSTANTS;
