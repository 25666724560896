import React, { useContext } from 'react';
import { Dialog, Link, Paragraph } from '@suncorp/styleguide-react-components';
import { isMobile } from '../../../utils/isMobile';
import { BrandContext } from '../../organisms/BrandContext';

const PolicyFinderDialogBox = () => {
  const brandConfig = useContext(BrandContext);
  return (
    <Dialog
      appearance="lite"
      clickOffToClose
      hasCloseButton
      id="my-dialog"
      title="Where to find your policy number"
      triggerAppearance="textOnly"
      triggerText="Forgot your policy number?"
      type={isMobile() ? 'modal' : 'tooltip'}
      width={350}
    >
      <Paragraph>
        Policy numbers can be found in your{' '}
        <Link href={brandConfig.links.wdmLoginLink} target="_blank">
          My Account
        </Link>{' '}
        or on your billing statement, policy documents, or any recent emails
        from us. It’s a combination of letters and numbers labelled as{' '}
        <span className="sg-Type--bold">"Policy Number"</span>.
      </Paragraph>
    </Dialog>
  );
};
export default PolicyFinderDialogBox;
