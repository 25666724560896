import React from 'react';
import { List } from '@suncorp/styleguide-react-components';
import * as PropType from 'prop-types';

const CommonProgressBarOrderedList = ({ children, completionRate, id }) => (
  <>
    <div className="sg-Type--size12 sg-u-largeHidden">
      {completionRate}% complete
    </div>
    <List
      id={id}
      appearance=""
      className="sg-Progress sg-Progress-list sg-Progress-list--leftAlign sg-u-mediumAndSmallHidden"
    >
      {children}
    </List>
  </>
);

CommonProgressBarOrderedList.propTypes = {
  children: PropType.node.isRequired,
  completionRate: PropType.string,
  id: PropType.string,
};

export default CommonProgressBarOrderedList;
