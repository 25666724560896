const AAMI_CONSTANTS = {
  // Readable brand name used in the display
  brandName: 'AAMI',
  // Readable brand name used in the PRNPolicyDisplay Page
  brandNameCaseSense: 'AAMI',
  // Brand name used in API calls
  apiBrandName: 'AAMI',
  supportedCards: ['visa', 'mastercard', 'americanexpress', 'eftpos'],
  links: {
    wdmLoginLink: 'https://online.aami.com.au/wdm/aami/#/login',
    contactUsLink: 'https://www.aami.com.au/contact.html',
    contactUsTelLink: 'tel:132244',
    privacyStatementLink: 'https://www.aami.com.au/privacy.html',
  },
  contactNumber: '13 22 44',
  copyrightStatement:
    '© Copyright 2023 AAI Limited ABN 48 005 297 807 trading as AAMI',
};

module.exports = AAMI_CONSTANTS;
