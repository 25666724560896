const APIA_CONSTANTS = {
  // Readable brand name used in the display
  brandName: 'APIA',
  // Readable brand name used in the PRNPolicyDisplay Page
  brandNameCaseSense: 'Apia',
  // Brand name used in API calls
  apiBrandName: 'APIA',
  supportedCards: ['visa', 'mastercard', 'americanexpress', 'eftpos'],
  links: {
    wdmLoginLink: 'https://online.apia.com.au/wdm/apia/#/login',
    contactUsLink: 'https://www.apia.com.au/contact-us.html',
    contactUsTelLink: 'tel:135050',
    privacyStatementLink:
      'https://www.apia.com.au/privacy/online-terms-conditions.html',
    policyDocumentsLink: 'https://www.apia.com.au/policy-documents.html',
  },
  contactNumber: '13 50 50',
};

module.exports = APIA_CONSTANTS;
